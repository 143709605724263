<template>
    <div>

        <van-overlay :show="true" z-index="999" v-if="!auth" />
        <div class="auth" v-if="!auth">
            <van-button round :color="config.main_color" type="primary" size="large" @click="get_auth()">{{auth_text}}
            </van-button>
            <div class="auth-desc">{{auth}}
                将获取您的微信头像和昵称办理年卡和付款用途，请点击授权！
            </div>
        </div>


    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { oauth } from "@/static/js/oauth";
    export default {
        name: "index",
        data() {
            return {
                auth: '',
                auth_text: '微信授权',
                auth_from: 'wechat',
            }
        },
        components: {},
        computed: {
            ...mapGetters(['card', 'unionid']),
        },
        created() {
            this.init();
        },
        methods: {
            ...mapMutations(['setCard']),

            init() {
                const isTTWebView = navigator.userAgent.toLowerCase().includes("toutiaomicroapp");
                if (isTTWebView) {
                    this.auth_text = '抖音授权';
                    this.auth_from = 'douyin';
                    if (this.$route.query.get_unionid) {
                        this.auth = this.$route.query.get_unionid;
                    } else {
                        this.auth = this.unionid;
                    }
                } else {
                    this.auth_text = '微信授权';
                    this.auth_from = 'wechat';
                    if (this.$route.query.get_unionid) {
                        this.auth = this.$route.query.get_unionid;
                    } else {
                        this.auth = this.unionid;
                    }
                }
            },


            get_auth() {
                oauth(this.auth_from);
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .auth {
        width: 240px;
        position: fixed;
        top: 200px;
        left: calc(50% - 140px);
        background-color: #fff;
        border-radius: 10px;
        z-index: 2001;
        padding: 40px 20px 20px;
    }

    .auth-desc {
        margin-top: 15px;
        font-size: 14px;
        color: #888;
        text-align: center;
    }

    .van-button--primary {
        background-image: url('../../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }
</style>