<template>
    <div>
        <div class="cate">
            <div class="cate-list">
                <van-tabs v-model:active="form.cate_id" type="card" :ellipsis="false"  @change="moreProduct">
                    <van-tab :title="item.cate_name2" :name="item.id" v-for="(item,index) in cate" :key="index"></van-tab>
                </van-tabs>
            </div>

            <div class="cate-name">
                <span></span>{{type.cate_name}}
                <div class="cate-money">￥{{type.cate_money}}</div>
            </div>
            <div class="cate-desc">
                {{type.card_about}}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: "index",
        data() {
            return {
                form: {},
                show_more: false,
            }
        },
        props: {
            cate: {
                type: Array,
                default: [],
            },
        },
        components: {},
        computed: {
            ...mapGetters(['card']),
            type() {
                if(!this.cate.length){
                    return {};
                }
                for (var item of this.cate) {
                    if (item.id * 1 == this.form.cate_id * 1) {
                        return item;
                    }
                }
                return this.cate[0];
            },
        },
        watch: {
            card(value) {
                if (value) {
                    this.form = value;
                }
            },
            form(value) {
                if (value) {
                    this.setCard(this.form);
                }
            }
        },
        created() {
            this.form = this.card;
        },
        methods: {
            ...mapMutations(['setCard']),

            //切换类型
            change_cate(index) {
                this.$set(this.form, 'cate', this.cate[index]);
                this.$set(this.form, 'is_handle', true);
                this.$set(this.form, 'cate_id', this.cate[index].id);
                this.setCard(this.form);
            },

            moreProduct(cate_id) {
                if(cate_id=='-1'){
                    this.show_more = true;
                    this.change_cate(3);
                } else if(cate_id=='-2'){
                    this.url('https://wap.lotsmall.cn/vue/detail/ticket?activityTags=&id=10911&m_id=290&refer=listPage');
                }else{
                    var index = 0;
                    for(var i in this.cate){
                        if(cate_id == this.cate[i].id){
                            index = i;
                        }
                    }
                    this.change_cate(index);
                }
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    /deep/ .van-tab{
        background: rgba(3, 136, 102, 0.1);
        transform: skewX(-10deg);
        border-radius: 5px;
        margin-right: 10px;
    }
    /deep/ .van-tab .van-tab__text {
        transform: skewX(10deg);
        font-family: 'FZCS';
        font-size: 14px;
        color: var(--main-color);
    }
    .cate {
        position: relative;
        margin-left: 20px;
        width: calc(100% - 40px);
        background: #fff;
        border-radius: 20px;
        margin-top: 150px;
        padding-bottom: 20px;
    }

    .cate-list {
        padding:20px 15px;
    }


    .cate-name {
        margin-left: 20px;
        width: calc(100% - 40px);
        font-size: 18px;
        font-family: 'FZCS';
        position: relative;
    }

    .cate-money {
        position: absolute;
        right: 0;
        top: -5px;
        color: #F53A1C;
        font-size: 24px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
    }

    .cate-name span {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: var(--main-color);
        margin-right: 10px;
    }

    .cate-desc {
        margin-left: 20px;
        width: calc(100% - 70px);
        background: #EAF9F5;
        border-radius: 6px;
        padding: 15px;
        font-size: 14px;
        color: #060920;
        margin-top: 16px;
    }

    /deep/ .van-tab--active {
        background: var(--main-color) !important;
        transform: skewX(-10deg);
        border-radius: 5px;
        font-size: 14px!important;
    }

    /deep/ .van-tab--active .van-tab__text {
        transform: skewX(10deg);
        color: #fff;
        font-family: 'FZCS';
        font-size: 14px;
    }

    /deep/ .van-tab {
        border: none !important;
        color: #000 !important;
        font-family: 'FZCS';
        font-size: 14px;
    }

    /deep/ .van-tabs__nav--card {
        border: none !important;
        padding-left: 10px;
    }
</style>