<template>
    <div>
        <van-popup v-model:show="show" position="top">
            <div class="menu">
                <div class="close">
                    <van-icon name="cross" @click="show = false" />
                </div>
                <van-row gutter="0">
                    <van-col span="12" v-for="(item,index) in menu" :key="index">
                        <div class="nav" @click="url(item.url)">
                            <div class="nav-icon">
                                <img :src="item.icon">
                            </div>
                            <div class="nav-text">{{item.name}}</div>
                        </div>
                    </van-col>

                    <van-col span="12" >
                        <div class="nav" @click="clean">
                            <div class="nav-icon">
                                <van-icon name="delete-o" />
                            </div>
                            <div class="nav-text">清理缓存</div>
                        </div>
                    </van-col>
                </van-row>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import { common_nav } from '@/api/common.js';
    import {Dialog,Toast} from 'vant';
    export default {
        name: "index",
        data() {
            return {
                menu: [],
                show: false,
            }
        },
        created() {
            this.get_data();
        },
        methods: {
            get_data() {
                common_nav({ id: 7 }).then(res => {
                    if (res.code == 1) {
                        this.menu = res.data;
                    }
                });
            },

            //清理缓存
			clean() {
				var that = this;
				Dialog.confirm({
					title: '提示',
					message: '确认要清理缓存吗？',
				}).then(() => {
					this.$store.commit('setUser', {});
					this.$store.commit('setOpenid', '');
					this.$store.commit('setUnionid', '');
					this.$store.commit('setCard', '');
                    localStorage.clear();
					window.location.href = '/';
                    Toast('清理成功');
				}).catch(() => {
				
				});
			},

            url(url) {
                if (url.indexOf('http') !== -1) {
                    location.href = url
                } else {
                    this.$router.push(url)
                }
            },
        }
    }
</script>

<style scoped>
    /deep/ .van-popup {
        background-color: transparent;
    }

    .close {
        position: fixed;
        right: 20px;
        top: 20px;
        font-size: 30px;
    }

    .menu {
        width: calc(100% - 120px);
        height: calc(100vh - 120px);
        background-color: rgba(11, 18, 34, .6);
        color: #D19A5A;
        padding: 60px;
        overflow-y: auto;
    }

    .nav {
        margin-bottom: 40px;
        text-align: center;
        font-family: 'FZCS';
    }

    .nav-icon {
        width: 80px;
        text-align: center;
        display: inline-block;
        color: #D19A5A;
        font-size: 80px;
    }

    .nav-text {
        width: 80px;
        text-align: center;
        display: inline-block;
    }

    .nav-icon img {
        width: 100%;
    }
</style>