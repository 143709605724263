<template>
    <div>
        <div class="bg-content">
            <div class="bg">
                <img src="@/static/image/index/1.jpg">
            </div>
            <div class="more">
                <div class="more-icon">
                    <img src="@/static/image/index/4.png">
                </div>
                <div class="more-text" @click="more">
                    更多功能
                </div>
                <div class="more-bottom">
                    <img src="@/static/image/index/8.png">
                </div>
            </div>
        </div>

        <!-- 加载菜单 -->
        <index-menu ref="menu"></index-menu>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import indexMenu from '@/pages/index/child/indexMenu.vue';
    export default {
        name: "index",
        data() {
            return {
                form: {},
                show_more: false,
            }
        },
        components: { indexMenu },
        computed: {
            ...mapGetters(['card']),
            type() {
                for (var item of this.cate) {
                    if (item.id * 1 == this.form.cate_id * 1) {
                        return item;
                    }
                }
                return this.cate[0];
            },
        },
        watch: {
            card(value) {
                if (value) {
                    this.form = value;
                }
            },
            form(value) {
                if (value) {
                    this.setCard(this.form);
                }
            }
        },
        created() {
            this.form = this.card;
        },
        methods: {
            ...mapMutations(['setCard']),
            more() {
                this.$refs.menu.show = true;
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .bg-content {
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .bg {
        position: absolute;
        left: 0;
        top: 0;
    }

    .bg img {
        width: 100%;
    }

    .more {
        position: fixed;
        right: 20px;
        top: 40px;
        height: 30px;
        background: var(--main-color);
        line-height: 30px;
        border-radius: 15px;
        width: 120px;
        z-index: 10;
    }

    .more-icon {
        position: absolute;
        left: 14px;
        top: 1px;
    }

    .more-icon img {
        width: 16px;
    }

    .more-text {
        position: absolute;
        left: 38px;
        font-family: 'FZCS';
        color: #fff;
        font-size: 15px;
        z-index: 999;
    }

    .more-bottom {
        position: absolute;
        right: 0;
        top: 0;
        height: 30px;
        z-index: 998;
    }

    .more-bottom img {
        height: 100%;
    }
</style>