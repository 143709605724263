<template>
    <div>
        <div class="sfz" v-if="type!=='other'">
            <van-uploader :after-read="upload" result-type="file">
                <div v-if="!url">
                    <div class="sfz-image">
                        <img src="@/static/image/index/5.png">
                    </div>
                    <div class="sfz-button">
                        <div class="sfz-icon">
                            <img src="@/static/image/index/3.png">
                        </div>
                        <div class="sfz-text">{{name}}</div>
                    </div>
                </div>
                <div class="cert" v-if="url">
                    <img :src="url">
                </div>
            </van-uploader>
        </div>

        <div class="other" v-if="type=='other'">
            <div class="other-name">请上传{{cate.other_name}}</div>
            <div class="other-image">
                <upload-image @callback="uploadOther" :max="cate.max_other" :list="[]"></upload-image>
            </div>
            <div class="other-desc">
                {{cate.other_demand}}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { Dialog, Toast } from 'vant';
    import { card_sfz, card_book } from '@/api/card.js';
    import { common_image } from '@/api/common.js';
    export default {
        name: "index",
        data() {
            return {
                form: {},
            }
        },
        props: {
            type: '',//上传类型，身份证sfz或户口本book
            name: '',
        },
        watch: {
            card(value) {
                if (value) {
                    this.form = value;
                }
            },
            form(value) {
                if (value) {
                    this.setCard(this.form);
                }
            }
        },
        created() {
            this.form = this.card;
        },
        computed: {
            ...mapGetters(['card']),
            cate() {
                return this.card.cate;
            },
            url() {
                if (this.form.cert_type == 1) {
                    return this.form.photo_sfz_1;
                }
                if (this.form.cert_type == 2) {
                    if(this.type=='book'){
                        return this.form.photo_book;
                    }
                    if(this.type=='person'){
                        return this.form.photo_person;
                    }
                }
            },
        },
        methods: {
            ...mapMutations(['setCard']),
            upload(file) {
                var form = new FormData();
                form.set("file", file.file);
                form.set("cate_id", this.cate.id);
                form.set("company_id", this.$route.query.id);

                Toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: '上传中……',
                });

                if (this.type == 'sfz') {
                    this.get_sfz(form);
                }

                if (this.type == 'book') {
                    this.get_book(form);
                }

                if (this.type == 'person') {
                    this.get_person(form);
                }
            },
            get_sfz(from) {
                card_sfz(from).then(res => {
                    if (res.code == 1) {
                        this.callback(res);
                    }
                });
            },

            get_book(from) {
                card_book(from).then(res => {
                    if (res.code == 1) {
                        this.callback(res);
                    }
                });
            },

            get_person(from) {
                common_image(from).then(res => {
                    if (res.code == 1) {
                        this.callback(res);
                    }
                });
            },

            callback(res) {
                Toast.clear();
                if (this.type == 'person') {
                    Toast('上传成功');
                    this.$set(this.form, 'photo_person', res.data);
                } else {
                    Toast('识别成功');
                    if (this.type == 'sfz') {
                        this.$set(this.form, 'photo_sfz_1', res.data.url);
                        this.$set(this.form, 'ocr_sfz', res.data.ocr_info);
                    }
                    if (this.type == 'book') {
                        this.$set(this.form, 'photo_book', res.data.url);
                        this.$set(this.form, 'ocr_book', res.data.ocr_info);
                    }
                    this.$set(this.form, 'is_handle', res.data.is_handle);
                    this.$set(this.form, 'name', res.data.ocr_info.name);
                    this.$set(this.form, 'sfz', res.data.ocr_info.idCardNo);
                }
                this.setCard(this.form);
            },

            //上传其他资料
            uploadOther(e) {
                this.other = e;
                this.$set(this.form, 'photo_other', this.other);
            },
        }
    }
</script>

<style scoped>
    .upload {
        margin-top: 20px;
        margin-left: 20px;
        width: calc(100% - 40px);
    }

    .sfz {
        margin-left: 20px;
        width: calc(100% - 40px);
        margin-top: 20px;
        background: #F2F2F2;
        position: relative;
    }

    .sfz-image {
        padding: 20px 50px;
    }

    .sfz-image img {
        width: 100%;
    }

    .sfz-button {
        position: absolute;
        top: 72px;
        left: calc(50% - 85px);
        text-align: center;
        background-color: var(--main-color);
        width: 170px;
        height: 40px;
        border-radius: 20px;
    }

    .sfz-icon {
        position: absolute;
        left: 20px;
        top: 10px;
    }

    .sfz-icon img {
        height: 20px;
    }

    .sfz-text {
        width: 140px;
        position: absolute;
        left: 20px;
        line-height: 40px;
        font-family: 'FZCS';
        color: #fff;
        font-size: 20px;
    }

    .cert {
        overflow: hidden;
    }

    .cert img {
        width: 100%;
    }

    .other {
        margin-left: 20px;
        width: calc(100% - 40px);
        margin-top: 30px;
    }

    .other-name {
        font-family: 'FZCS';
        font-size: 16px;
    }

    .other-image {
        margin-top: 10px;
    }

    .other-desc {
        background: #EAF9F5;
        border-radius: 6px;
        padding: 15px;
        font-size: 14px;
        color: #060920;
        margin-top: 16px;
    }
</style>