<template>
    <div>
        <div class="user-submit">
            <div class="agree">
                <van-checkbox v-model="agree" shape="square" :checked-color="config.main_color">阅读并同意
                </van-checkbox>
                <span class="handle" @click="show_demand = true">《用户办理须知》</span>
            </div>
            <van-button round :disabled="!agree" type="primary" @click="submit" :color="config.main_color" size="large">
                提交办理
            </van-button>
        </div>

        <!--办理须知内容-->
        <van-action-sheet v-model="show_demand" title="用户办理须知" :round="true">
            <div class="person-demand" v-html="cate.demand"></div>
        </van-action-sheet>

    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { Toast, Notify, } from 'vant';
    import { oauth } from "@/static/js/oauth";
    export default {
        name: 'index',
        data() {
            return {
                show_demand: false,
                agree: false,
            }
        },
        computed: {
            ...mapGetters(['card','unionid']),
            cate() {
                return this.card.cate;
            },
        },
        methods: {
            ...mapMutations(['setCard']),
            submit() {
                if(!this.unionid){
                    oauth();
                    return false;
                }
                
                this.$emit('submit');
            },
        }
    }
</script>

<style>
    .van-button--primary {
        background-image: url('../../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    .user-submit {
        margin: 30px 20px 20px;
        width: calc(100% - 40px);
        font-family: 'FZCS';
    }

    .agree {
        padding-left: 0;
        color: var(--main-color);
        position: relative;
        height: 40px;
        line-height: 40px;
    }

    .agree .handle {
        position: absolute;
        left: 110px;
        top: -10px;
        font-size: 14px;
    }

    .agree .van-checkbox__label {
        color: #999;
        font-size: 14px;
    }



    .person-demand {
        padding: 15px;
        font-size: 14px;
        line-height: 1.5;
        color: #666;
    }

    .person-demand img {
        max-width: 100%;
    }
</style>