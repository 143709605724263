<template>
    <div>
        <!-- 顶部背景 -->
        <card-bg></card-bg>

        <!-- 年卡分类 -->
        <card-cate :cate="cate"></card-cate>

        <!-- 办理单位 -->
        <card-company :info="info" :dep="dep" :dep_list="dep_list" v-if="form.is_company==1"></card-company>

        <!-- 证件上传 -->
        <card-upload v-if="!form.token"></card-upload>

        <!-- 用户信息 -->
        <card-info></card-info>

        <!-- 提交办理 -->
        <card-demand @submit="submit"></card-demand>

        <!-- 授权 -->
        <card-oauth></card-oauth>

    </div>
</template>

<script>
    var jweixin = require('jweixin-module');
    import { Dialog, Toast } from 'vant';
    import { mapGetters, mapMutations } from 'vuex';
    import cardBg from '@/pages/index/child/cardBg.vue';
    import cardCate from '@/pages/index/child/cardCate.vue';
    import cardUpload from '@/pages/index/child/cardUpload.vue';
    import cardInfo from '@/pages/index/child/cardInfo.vue';
    import cardDemand from '@/pages/index/child/cardDemand.vue';
    import cardCompany from '@/pages/index/child/cardCompany.vue';
    import cardOauth from '@/pages/index/child/cardOauth.vue';
    import { check_info } from '@/static/js/common.js';
    import { card_cate, card_sale, card_person_submit, card_pay_submit, card_order_detail } from '@/api/card.js';
    import { company_cate, company_info, company_submit } from '@/api/company.js';
    import { wxPay } from '@/static/js/pay.js';

    export default {
        name: "index",
        data() {
            return {
                form: {},//提交数据
                cate: [],//分类
                info: {},//企业信息
                company_id: 0,
                dep: [],//内部部门
                dep_list: [],
                sale_id: '',
                is_renshe: false,
                plrs: {},//人社局回调信息
                sfz_ocr_info: {},//身份证识别，人社局字段
                isMiniProgram: false
            }
        },
        components: { cardBg, cardCate, cardUpload, cardInfo, cardDemand, cardCompany, cardOauth },
        created() {
            this.init();
        },
        computed: {
            ...mapGetters(['card', 'user', 'unionid']),
        },
        methods: {
            ...mapMutations(['setCard']),

            //初始化
            init() {
                //小程序webviewtoken判断
                if (document.location.href.indexOf('?') !== -1) {
                    let querys = Object.fromEntries(new URLSearchParams(document.location.href.split('?')[1]))
                    if (querys.token) {
                        this.isMiniProgram = true
                        console.log('小程序webview模式')
                    }
                }
                this.save_sale();
                this.setCard({});
                if (!this.card || !this.card.name) {
                    this.form = {
                        cate: {},
                        photo_sfz_1: '',
                        photo_book: '',
                        photo_person: '',
                        photo_other: [],
                        ocr_sfz: {},
                        ocr_book: {},
                        name: '',
                        sfz: '',
                        tel: '',
                        code: '',
                        card_is_express: 0,
                        address1: '',
                        address2: '',
                        cate_id: 2,
                        is_handle: true,
                        cert_type: 1,//1是身份证，2是户口本
                        token: this.$route.query.token,
                        is_company: 2,//个人，1是企业
                        company_id: 0,
                        user_dep_name: '',
                        user_dep_id: '',
                    }
                    this.setCard(this.form);
                } else {
                    this.form = this.card;
                }


                //是否是重新办理
                const user_id = this.$route.query.user_id;
                if (user_id) {
                    this.get_order(user_id);
                }

                //是否企业办理
                if (this.$route.params.id) {
                    this.company_id = this.$route.params.id;
                    this.$set(this.form, 'company_id', this.$route.params.id);
                } else {
                    this.company_id = this.$route.query.id;
                    this.$set(this.form, 'company_id', this.$route.query.id);
                }

                if (this.company_id > 0) {
                    this.$set(this.form, 'is_company', 1);
                    this.get_company();
                } else {
                    this.$set(this.form, 'is_company', 2);
                    this.get_cate();
                }

            },



            //写入sale_id 
            save_sale() {
                const sale_id = this.$store.getters.sale_id;
                if (this.$route.params.sale_id) {
                    this.sale_id = this.$route.params.sale_id;
                } else {
                    this.sale_id = this.$route.query.sale_id;
                }
                if (this.sale_id) {
                    this.$store.commit('setSaleId', this.sale_id);
                }

                if (!this.sale_id && sale_id) {
                    this.sale_id = sale_id;
                }
            },

            //年卡类型
            get_cate() {
                card_cate({ token: this.form.token }).then(res => {
                    if (res.code == 1) {
                        this.cate = res.data.cate;
                        if (!this.form.cate.id) {
                            this.$set(this.form, 'cate', res.data.cate[0]);
                            this.$set(this.form, 'cate_id', res.data.cate[0].id);
                        }
                        this.get_sale();
                        if (this.form.token) {
                            if (res.data.plrs.end_state == 1) {
                                Dialog.alert({
                                    message: '您已经办理过了，点击确认查看详情！',
                                }).then(function () {
                                    this.url('/order_detail?id=' + res.data.plrs.card_id);
                                });
                                return false;
                            }
                            this.plrs = res.data.plrs;
                            this.$set(this.form, 'name', res.data.plrs.userName);
                            this.$set(this.form, 'sfz', res.data.plrs.userCertNum);
                            this.$set(this.form, 'tel', res.data.plrs.userMobile);
                            this.$set(this.form, 'card_cert_type', 1);
                            this.$set(this.form, 'is_handle', 1);
                            this.$set(this.form, 'sale_id', 17756);
                            this.sale_id = 17756;
                            this.is_renshe = true;
                            this.sfz_ocr_info = {
                                faceImg: this.plrs.userCard,
                                idCardNo: this.plrs.userCertNum,
                                address: this.plrs.address,
                                sex: '男',
                                race: '汉',
                                name: this.plrs.userName,
                                birth: this.plrs.birth,
                                is_handle: 1,
                                city: this.plrs.city,
                            }
                            // this.$set(this.form, 'sfz_ocr_info', );
                        } else {
                            this.$set(this.form, 'name', '');
                            this.$set(this.form, 'sfz', '');
                            this.$set(this.form, 'tel', '');
                            this.$set(this.form, 'card_cert_type', 1);
                            this.$set(this.form, 'sfz_ocr_info', null);
                        }



                        if(res.data.from.from_notice_state == 1){
                            // const msg =  localStorage.getItem('msg');
                            // if(!msg){
                                Dialog.alert({
                                    title: '温馨提示',
                                    message: res.data.from.from_notice_content,
                                }).then(function(){
                                    // localStorage.setItem('msg', 1);
                                });
                            // }
                        }
                    }
                });
            },

            //写入代理信息
            get_sale() {
                card_sale({}).then();
            },

            //企业信息
            get_company() {
                company_info({
                    id: this.company_id,
                }).then(res => {
                    if (res.code == 1) {
                        if (res.data.state == 0) {
                            Dialog.alert({
                                message: '当前企业在审核中！',
                            }).then(res2 => {
                                this.url('/');
                            });
                            return false;
                        }
                        this.info = res.data;
                        this.dep = res.data.dep;
                        if (res.data.dep.length) {
                            for (var item of res.data.dep) {
                                this.dep_list.push(item.name);
                            }
                        }

                        //获取办理分类
                        company_cate({
                            cate_ids: res.data.company_authority,
                        }).then(res2 => {
                            if (res2.code == 1) {
                                this.cate = res2.data;
                                this.form.cate = res2.data[0];
                                this.form.cate.is_check_tel = this.info.is_check_tel;
                                this.form.cate_id = res2.data[0].id;
                            }
                        });

                        //自动选择部门
                        if (this.$route.query.dep_id) {
                            var dep_id = this.$route.query.dep_id;
                            for (var item of this.dep) {
                                if (item.id == dep_id * 1) {
                                    this.form.user_dep_id = dep_id * 1;
                                    this.form.user_dep_name = item.name;
                                }
                            }
                        }

                        
                    }
                });
            },

            //获取订单信息
            get_order(user_id) {
                card_order_detail({ id: user_id }).then(res => {
                    if (res.code == 1) {
                        const data = res.data;
                        this.form = {
                            cate: data.cate,
                            photo_sfz_1: data.photo_sfz_1,
                            photo_book: data.photo_book,
                            photo_person: data.photo_person,
                            photo_other: data.photo_other,
                            ocr_sfz: data.sfz_ocr_info,
                            ocr_book: data.sfz_ocr_info,
                            name: data.name,
                            sfz: data.sfz,
                            tel: data.tel,
                            code: '',
                            card_is_express: 0,
                            address1: '',
                            address2: '',
                            cate_id: data.cate_id,
                            is_handle: data.is_handle,
                            cert_type: data.card_cert_type,
                            token: '',
                            is_company: 2,//个人，1是企业
                            company_id: 0,
                        }
                        this.setCard(this.form);
                    }
                });
            },

            //提交办理
            submit() {
                let that = this;
                let param = this.card;
                const data = check_info(param);
                if (!data) {
                    return false;
                }

                //个人办理
                if (this.form.is_company == 2) {
                    data.is_renshe = this.is_renshe ? 1 : 0;
                    data.plrs = this.plrs;
                    if (this.is_renshe) {
                        data.sfz_ocr_info = this.sfz_ocr_info;
                        data.openid1 = this.$route.query.openId
                    }
                    card_person_submit(data).then(res => {
                        if (res.code == 1) {
                            if (this.isMiniProgram) {
                                card_pay_submit({ id: res.data, openid1: this.$route.query.openId }).then(res2 => {
                                    if (res2.code == 1) {
                                        let payDataStr = encodeURIComponent(JSON.stringify(res2.data.info));//因为要吧参数传递给小程序，所以这里需要转为字符串
                                        const url = `/newPages/pages/wxpay/wxPay?payDataStr=${payDataStr}`;
                                        jweixin.miniProgram.redirectTo({
                                            url: url,
                                            fail: (err) => {
                                                Dialog.alert({
                                                    message: JSON.stringify(err),
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                            else {
                                card_pay_submit({ id: res.data }).then(res2 => {
                                    if (res2.code == 1) {
                                        wxPay(res2.data.info).then(res3 => {
                                            this.setCard(null);
                                            that.$router.replace('/order_detail?id=' + res2.data.id);
                                        }).catch(err => {
                                            that.$router.replace('/order_detail?id=' + res2.data.id);
                                        });
                                    }
                                });
                            }
                        }
                    });
                } else {
                    if (this.dep.length) {
                        if (!this.form.user_dep_id) {
                            Toast('内部部门不得为空，请选择!')
                            return false;
                        }
                    }
                    company_submit(data).then(res => {
                        if (res.code == 1) {
                            Dialog.alert({
                                message: res.msg,
                            });
                        }
                    });
                }
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>