<template>
    <div>
        <div class="info">
            <div class="title">
                <div class="title-text">用户信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>

            <div class="info-content">
                <van-cell-group>
                    <van-field label-width="70" label-class="label" v-model="form.name" @input="change"
                        :disabled="form.token?true:false" label="真实姓名" placeholder="请输入真实姓名"></van-field>
                    <van-field label-width="70" label-class="label" v-model="form.sfz" :disabled="form.token?true:false"  @input="change"
                        label="身份证号" placeholder="请输入身份证号"></van-field>
                    <common-msg @callback="callback" :token="form.token" :show="cate.is_check_tel?true:false"
                        :param-tel="form.tel" :param-code="form.code"></common-msg>
                </van-cell-group>
            </div>
        </div>


        <!-- 邮递信息 -->
        <div class="info" v-if="cate.is_express && form.is_company == 2">
            <div class="title">
                <div class="title-text">收货地址</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-field label-width="70" label-class="label" name="radio" label="领取方式">
                <template #input>
                    <van-radio-group v-model="form.card_is_express" direction="horizontal"
                        :checked-color="config.main_color">
                        <van-radio :name="0"><span class="main-color">门店自取</span></van-radio>
                        <van-radio :name="1"><span class="main-color">快递送达</span></van-radio>
                    </van-radio-group>
                </template>
            </van-field>

            <van-field label-width="70" label-class="label" v-if='form.card_is_express == 1' name="快递费用" label="快递费用"
                :value="cate.express_money + '元'" readonly>
            </van-field>

            <van-field label-width="70" label-class="label" v-if='form.card_is_express == 1' readonly clickable
                name="area" :value="form.address1" label="地区选择" placeholder="点击选择省市区" @click="showArea = true">
            </van-field>

            <van-popup label-width="70" label-class="label" v-model="showArea" position="bottom">
                <van-area :area-list="areaList" @confirm="confirm_address" @cancel="showArea = false"></van-area>
            </van-popup>

            <van-field label-width="70" label-class="label" v-if='form.card_is_express == 1' v-model="form.address2"
                rows="2" autosize label="街道地址" type="textarea" maxlength="100" show-word-limit></van-field>

            <van-field label-width="70" label-class="label" v-if='form.card_is_express == 0' name="领取地点" label="领取地点"
                value="平凉十中对面崆峒山年卡办理中心" readonly>
            </van-field>

        </div>


    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { card_cate } from '@/api/card.js';
    import {
        address_info,
    } from '@/static/js/common.js';
    export default {
        name: "index",
        data() {
            return {
                areaList: address_info(),
                showArea: false,
                form: {},
            }
        },

        watch: {
            card(value) {
                if (value) {
                    this.form = value;
                }
            },
            form(value) {
                if (value) {
                    this.setCard(this.form);
                }
            }
        },
        components: {},
        created() {
            this.form = this.card;
        },
        computed: {
            ...mapGetters(['card']),
            cate() {
                return this.card.cate;
            },
        },
        methods: {
            ...mapMutations(['setCard']),

            change(){
                this.setCard(this.form);
            },

            //发送验证码回调
            callback(e) {
                if (e.type == 'input') {
                    if (e.field == 'tel') {
                        this.$set(this.form, 'tel', e.value);
                    }
                    if (e.field == 'code') {
                        this.$set(this.form, 'code', e.value);
                    }
                }
                this.setCard(this.form);
            },

            //确认地址
            confirm_address(values) {
                this.form.address1 = values.filter((item) => !!item).map((item) => item.name).join('');
                this.showArea = false;
            },
        }
    }
</script>

<style scoped>
    .info {
        margin-left: 20px;
        width: calc(100% - 40px);
        font-family: 'FZCS';
        margin-top: 20px;
        border-radius: 15px;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .main-color {
        color: var(--main-color);
    }
</style>