<template>
    <div>
        <div class="upload">
            <div class="cate">
                <div class="cate-content" :class="form.cert_type==2?'active1':''" @click="change(1)">
                    身份证
                    <div class="bottom">
                        <span></span>
                    </div>
                </div>
                <div class="cate-content" :class="form.cert_type==1?'active2':''" @click="change(2)">
                    户口本
                    <div class="bottom">
                        <span></span>
                    </div>
                </div>
            </div>

            <!-- 上传身份证 -->
            <card-image ref="upload" name="上传身份证" type="sfz"  company_id="" v-if="form.cert_type == 1"></card-image>

            <!-- 上传户口本 -->
            <card-image ref="upload" name="上传户口本" type="book" v-if="form.cert_type == 2"></card-image>

            <!-- 个人照片 -->
            <card-image ref="upload" name="个人照片" type="person" v-if="form.cert_type == 2"></card-image>

            <!-- 其他资料 -->
            <card-image ref="upload" name="其他资料" type="other" v-if="!form.is_handle  || cate.is_other == 1">
            </card-image>


        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import cardImage from '@/pages/index/child/cardImage.vue';
    import { card_cate } from '@/api/card.js';
    export default {
        name: "index",
        data() {
            return {
                form: {},
            }
        },
        watch: {
            card(value) {
                if (value) {
                    this.form = value;
                }
            },
            form(value) {
                if (value) {
                    this.setCard(this.form);
                }
            }
        },
        components: { cardImage },
        created() {
            this.form = this.card;
        },
        computed: {
            ...mapGetters(['card']),
            cate() {
                return this.card.cate;
            },
        },
        methods: {
            ...mapMutations(['setCard']),

            // 切换证件类型
            change(index) {
                this.$set(this.form, 'cert_type', index);
            },
        }
    }
</script>

<style scoped>
    .upload {
        margin-top: 20px;
        margin-left: 20px;
        width: calc(100% - 40px);
        background: #fff;
        border-radius: 20px;
        overflow: hidden;
        padding-bottom: 20px;
    }

    .cate {
        height: 80px;
    }

    .cate-content {
        display: inline-block;
        width: 50%;
        text-align: center;
        line-height: 80px;
        font-family: 'FZCS';
        font-size: 22px;
        color: #060920;
        position: relative;
    }

    .cate .active1,
    .cate .active2 {
        background-color: #EAF9F5;
        color: var(--main-color);
    }

    .cate .active1 {
        border-bottom-right-radius: 30px;
        border-top-left-radius: 10px;
    }

    .cate .active2 {
        border-bottom-left-radius: 30px;
        border-top-right-radius: 10px;
    }

    .bottom {
        position: absolute;
        left: 0;
        top: 15px;
        text-align: center;
        height: 10px;
        width: 100%;
        display: block;
    }

    .active1 .bottom,
    .active2 .bottom {
        display: none;
    }

    .bottom span {
        display: inline-block;
        height: 5px;
        line-height: 5px;
        border-radius: 3px;
        width: 20px;
        background: var(--main-color);
    }
</style>