<template>
    <div>
        <div class="info">
            <!--企业信息-->
            <div class="title">
                <div class="title-text">办理单位</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell label-width="70" label-class="label" title="办理单位" :value="info.company_name"></van-cell>

            <!-- 内部部门 -->
            <van-field label-width="70" label-class="label" v-model="form.user_dep_name" readonly clickable
                v-if="dep.length" label="内部部门" placeholder="选择部门" @click="dep_show = true" />
            <van-popup v-model:show="dep_show" round position="bottom">
                <van-picker title="内部部门" :columns="dep_list" :show-toolbar="true" @confirm="depConfirm"
                    @cancel="dep_show = false">
                </van-picker>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: "index",
        data() {
            return {
                form: {},
                dep_show: false,
            }
        },
        props: {
            info: {},
            dep: [],
            dep_list: [],
        },
        computed: {
            ...mapGetters(['card']),
        },
        watch: {
            card(value) {
                if (value) {
                    this.form = value;
                }
            },
            form(value) {
                if (value) {
                    this.setCard(this.form);
                }
            }
        },
        created() {
            this.form = this.card;
        },
        methods: {
            ...mapMutations(['setCard']),

            depConfirm(value, index) {
                this.form.user_dep_name = value;
                this.form.user_dep_id = this.dep[index].id;
                this.dep_show = false;
            },

        }
    }
</script>

<style scoped>
    .info {
        margin-left: 20px;
        width: calc(100% - 40px);
        font-family: 'FZCS';
        margin-top: 20px;
        border-radius: 15px;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .main-color {
        color: var(--main-color);
    }

    .van-cell__value {
        flex: none;
        text-align: left;
        color: var(--main-color);
        width: calc(100% - 70px);
    }

    .van-cell__title {
        flex: none;
        width: 70px;
        color: var(--main-color);
    }
</style>